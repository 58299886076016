@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

html {
  background: #f2f2f2; }

.clearfix {
  clear: both; }

p, h1, h2, h3, h4, h5, h6, span, div, a, li, button, text, .title {
  font-family: "Source Sans Pro", sans-serif; }

.main-wrap {
  width: 100%;
  margin: 0 auto;
  display: block; }
  .main-wrap .cell {
    display: inline-block;
    box-sizing: border-box;
    vertical-align: top;
    float: left;
    padding: 15px; }
    .main-wrap .cell.over-latest-wrapper {
      padding: 0; }
      .main-wrap .cell.over-latest-wrapper .over-latest {
        background: #fff; }
        .main-wrap .cell.over-latest-wrapper .over-latest .title {
          color: #000; }
        .main-wrap .cell.over-latest-wrapper .over-latest .arrow {
          background-image: url(/static/media/grey-chevron.9285376b.svg); }
        .main-wrap .cell.over-latest-wrapper .over-latest tbody tr:last-child {
          background: #e0e0e0; }
          .main-wrap .cell.over-latest-wrapper .over-latest tbody tr:last-child th {
            font-weight: bold; }
    .main-wrap .cell.summary-wrap {
      padding: 0;
      margin-top: 12px; }
      .main-wrap .cell.summary-wrap .summary-stats {
        padding: 0; }
        .main-wrap .cell.summary-wrap .summary-stats:first-child {
          padding-right: 12px; }
        .main-wrap .cell.summary-wrap .summary-stats:last-child {
          padding-left: 12px; }
    .main-wrap .cell.pie-wrap {
      padding: 0; }
      .main-wrap .cell.pie-wrap .pie-view {
        margin: 0 auto;
        max-width: 380px; }
    .main-wrap .cell.no-pad {
      padding: 0; }
    .main-wrap .cell.small-pad {
      padding: 5px; }

.small-1 {
  width: calc( (100% / 12) * 1 ); }

.small-2 {
  width: calc( (100% / 12) * 2 ); }

.small-3 {
  width: calc( (100% / 12) * 3 ); }

.small-4 {
  width: calc( (100% / 12) * 4 ); }

.small-5 {
  width: calc( (100% / 12) * 5 ); }

.small-6 {
  width: calc( (100% / 12) * 6 ); }

.small-7 {
  width: calc( (100% / 12) * 7 ); }

.small-8 {
  width: calc( (100% / 12) * 8 ); }

.small-9 {
  width: calc( (100% / 12) * 9 ); }

.small-10 {
  width: calc( (100% / 12) * 10 ); }

.small-11 {
  width: calc( (100% / 12) * 11 ); }

.small-12 {
  width: calc( (100% / 12) * 12 ); }

@media screen and (min-width: 640px) {
  .medium-1 {
    width: calc( (100% / 12) * 1 ); }

  .medium-2 {
    width: calc( (100% / 12) * 2 ); }

  .medium-3 {
    width: calc( (100% / 12) * 3 ); }

  .medium-4 {
    width: calc( (100% / 12) * 4 ); }

  .medium-5 {
    width: calc( (100% / 12) * 5 ); }

  .medium-6 {
    width: calc( (100% / 12) * 6 ); }

  .medium-7 {
    width: calc( (100% / 12) * 7 ); }

  .medium-8 {
    width: calc( (100% / 12) * 8 ); }

  .medium-9 {
    width: calc( (100% / 12) * 9 ); }

  .medium-10 {
    width: calc( (100% / 12) * 10 ); }

  .medium-11 {
    width: calc( (100% / 12) * 11 ); }

  .medium-12 {
    width: calc( (100% / 12) * 12 ); } }
@media screen and (min-width: 1280px) {
  .large-1 {
    width: calc( (100% / 12) * 1 ); }

  .large-2 {
    width: calc( (100% / 12) * 2 ); }

  .large-3 {
    width: calc( (100% / 12) * 3 ); }

  .large-4 {
    width: calc( (100% / 12) * 4 ); }

  .large-5 {
    width: calc( (100% / 12) * 5 ); }

  .large-6 {
    width: calc( (100% / 12) * 6 ); }

  .large-7 {
    width: calc( (100% / 12) * 7 ); }

  .large-8 {
    width: calc( (100% / 12) * 8 ); }

  .large-9 {
    width: calc( (100% / 12) * 9 ); }

  .large-10 {
    width: calc( (100% / 12) * 10 ); }

  .large-11 {
    width: calc( (100% / 12) * 11 ); }

  .large-12 {
    width: calc( (100% / 12) * 12 ); } }
.select-search {
  width: 300px;
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
  display: inline-block; }

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit; }

/**
 * Value wrapper
 */
.select-search__value {
  position: relative;
  z-index: 1; }

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px; }

/**
 * Input
 */
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none; }

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none; }

.select-search__input:not([readonly]):focus {
  cursor: auto;
  cursor: initial; }

/**
 * Options wrapper
 */
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15); }

/**
 * Options
 */
.select-search__options {
  list-style: none; }

/**
 * Option row
 */
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee; }

/**
 * Option
 */
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer; }

.select-search--multiple .select-search__option {
  height: 48px; }

.select-search__option.is-selected {
  background: #5582de;
  color: #fff; }

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1); }

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #5582de;
  color: #fff; }

/**
 * Group
 */
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px; }

/**
 * States
 */
.select-search.is-disabled {
  opacity: 0.5; }

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px; }

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer; }

/**
 * Modifiers
 */
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden; }

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none; }

.select-search--multiple .select-search__input {
  cursor: auto;
  cursor: initial; }

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0; }

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default; }

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #5582de; }

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px; }

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px; }

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888; }

.loginMain {
  min-height: 100vh;
  margin: 0;
  border: 0;
  box-sizing: border-box; }
  .loginMain > h1 {
    color: #fff; }
  .loginMain .login-box {
    background-color: #23A6A6;
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); }
    .loginMain .login-box h2 {
      color: #f8f8f8;
      padding: 12px 16px;
      border-bottom: 1px solid #fff; }
    .loginMain .login-box .login-label {
      font-weight: 700;
      text-align: left;
      display: block;
      margin-bottom: 4px;
      color: #f8f8f8;
      font-size: 13px; }
    .loginMain .login-box .login-field {
      display: block;
      width: 100%;
      max-width: 100%;
      font-size: 16px;
      font-family: "Source Sans Pro", sans-serif;
      padding: 10px 16px;
      box-sizing: border-box;
      border: 1px solid #ececec;
      background-color: #f8f8f8;
      color: #000; }
    .loginMain .login-box .av-button {
      margin-right: 0;
      margin-bottom: 12px; }
    .loginMain .login-box .small-link {
      color: #1e616e;
      font-size: 13px;
      cursor: pointer;
      display: inline-block;
      margin-bottom: 12px; }
      .loginMain .login-box .small-link:hover {
        text-decoration: underline; }
  .loginMain .reg-box, .loginMain .sub-box {
    background-color: #23A6A6;
    display: block;
    max-width: 540px;
    margin: 0 auto;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); }
    .loginMain .reg-box h2, .loginMain .sub-box h2 {
      color: #f8f8f8;
      padding: 12px 16px;
      border-bottom: 1px solid #fff; }
    .loginMain .reg-box .reg-label, .loginMain .sub-box .reg-label {
      font-weight: 700;
      text-align: left;
      display: block;
      margin-bottom: 4px;
      color: #f8f8f8;
      font-size: 10px; }
      .loginMain .reg-box .reg-label.tick-text .reg-field, .loginMain .sub-box .reg-label.tick-text .reg-field {
        width: auto;
        display: inline-block; }
    .loginMain .reg-box .reg-field, .loginMain .sub-box .reg-field {
      display: block;
      width: 100%;
      max-width: 100%;
      font-size: 16px;
      font-family: "Source Sans Pro", sans-serif;
      padding: 10px 16px;
      box-sizing: border-box;
      border: 1px solid #ececec;
      background-color: #f8f8f8;
      color: #000; }
    .loginMain .reg-box .reg-select, .loginMain .sub-box .reg-select {
      display: block;
      font-size: 16px;
      font-family: "Source Sans Pro", sans-serif;
      padding: 10px 16px;
      box-sizing: border-box;
      margin: 0;
      cursor: pointer;
      border: 1px solid #ececec;
      border-radius: 0;
      width: 100%;
      max-width: 100%;
      -webkit-appearance: none;
      appearance: none;
      background-color: #f8f8f8;
      background-image: url(/static/media/drop-arrow.11da6577.svg);
      background-repeat: no-repeat;
      background-position: right .7em top 50%, 0 0;
      background-size: .65em auto, 100%; }
    .loginMain .reg-box .main-wrap p, .loginMain .sub-box .main-wrap p {
      color: #fff; }
      .loginMain .reg-box .main-wrap p:first-child, .loginMain .sub-box .main-wrap p:first-child {
        margin-top: 0; }
      .loginMain .reg-box .main-wrap p:last-child, .loginMain .sub-box .main-wrap p:last-child {
        margin-bottom: 0; }
  .loginMain .sub-box {
    max-width: none; }
  .loginMain body {
    background-color: #FFFFFF; }
  .loginMain p, .loginMain span, .loginMain s, .loginMain li, .loginMain textarea {
    font-family: "Source Sans Pro", sans-serif; }
  .loginMain h1, .loginMain h2, .loginMain h3, .loginMain h4, .loginMain h5, .loginMain h6 {
    font-family: "Source Sans Pro", sans-serif; }
  .loginMain .clearfix::after {
    content: "";
    clear: both;
    display: table; }
  .loginMain .big-logo {
    background: url(/static/media/avani_logo.bf41b755.svg);
    background-repeat: no-repeat;
    height: 120px;
    margin: 50px;
    background-size: contain;
    background-position: center center; }
  .loginMain .cancel-bg {
    background-color: #666666; }
  .loginMain .av-button div {
    display: inline-block; }

.off-button {
  padding: 12px 30px;
  border-radius: 5px;
  display: inline-block;
  background-color: #d3d3d3;
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
  cursor: pointer;
  transition: 0.2s all ease;
  vertical-align: middle;
  margin-right: 24px; }
  .off-button:hover {
    background-color: #aaaaaa; }

.av-button {
  padding: 12px 30px;
  border-radius: 5px;
  display: inline-block;
  background-color: #1e616e;
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
  cursor: pointer;
  transition: 0.2s all ease;
  vertical-align: middle;
  margin-right: 24px;
  border: solid 1px white; }
  .av-button:hover {
    background-color: #083745; }

.segmented {
  margin-top: 6px; }
  .segmented .av-button {
    margin-right: 0px;
    border-radius: 0px;
    padding: 8px 30px; }
  .segmented .off-button {
    margin-right: 0px;
    border-radius: 0px;
    padding: 8px 30px;
    color: #333; }

.footer {
  background-color: #23A6A6;
  color: #ffffff;
  width: 100%;
  min-width: 1000px;
  height: 60px;
  display: inline-block;
  line-height: 60px; }

.navbar {
  background-color: #23A6A6;
  color: #ffffff;
  width: 100%;
  min-width: 1000px;
  height: 60px; }
  .navbar h1 {
    display: inline-block;
    -webkit-margin-before: 0px;
            margin-block-start: 0px;
    padding-left: 10px;
    padding-right: 10px;
    color: #fff;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    font-size: 30px; }
  .navbar .navinfo {
    display: inline-block;
    text-align: left;
    width: 50%;
    padding-top: 10px; }
    .navbar .navinfo h1 {
      font-size: 20px;
      font-weight: 300; }
    .navbar .navinfo h1:before {
      content: "";
      background-image: url(/static/media/menu-avani-logo.903aabfb.svg);
      width: 196px;
      height: 40px;
      display: inline-block;
      background-repeat: no-repeat;
      vertical-align: -13px; }
    .navbar .navinfo .lia {
      font-size: 14px;
      font-weight: 400;
      font-family: "Source Sans Pro", sans-serif;
      display: inline-block; }
  .navbar .navbuttons {
    display: inline-block;
    width: 50%;
    padding-top: 10px;
    text-align: right; }
    .navbar .navbuttons .navbutton {
      padding: 8px 20px;
      display: inline-block;
      background-color: #23A6A6;
      font-family: "Source Sans Pro", sans-serif;
      border: 1px solid #fff;
      color: #fff;
      cursor: pointer;
      transition: 0.2s background ease;
      margin-right: 8px; }
      .navbar .navbuttons .navbutton:hover {
        background-color: #23bcbc; }
      .navbar .navbuttons .navbutton.active_section {
        background: rgba(255, 255, 255, 0.3); }
    .navbar .navbuttons .notifbutton {
      padding: 8px 20px;
      border-radius: 25px;
      display: inline-block;
      background-color: #23A6A6;
      font-family: "Source Sans Pro", sans-serif;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 30px;
      cursor: pointer;
      transition: 0.2s background ease;
      margin-right: 12px; }
      .navbar .navbuttons .notifbutton:hover {
        background-color: #23bcbc; }
      .navbar .navbuttons .notifbutton.active_section {
        background: rgba(255, 255, 255, 0.3); }

.clientsheader {
  background-color: #1e616e;
  padding: 10px;
  padding-bottom: 0;
  text-align: left;
  color: #3d3d3d; }
  .clientsheader .title {
    font-size: 30px;
    font-weight: bold;
    color: #fff; }
  .clientsheader .bread {
    color: #fff;
    font-weight: 400;
    font-size: 16px; }
  .clientsheader .tabs {
    margin-top: 8px; }
    .clientsheader .tabs div {
      padding: 8px 20px;
      display: inline-block;
      cursor: pointer;
      background: #d2d2d2;
      margin-right: 10px; }
    .clientsheader .tabs .selected {
      background: #f2f2f2;
      cursor: auto; }
  .clientsheader .search {
    float: right; }
    .clientsheader .search input {
      border: 2px solid #606060;
      border-radius: 20px;
      height: 24px;
      width: 233px;
      padding-left: 10px;
      padding-right: 10px;
      font-family: "Source Sans Pro", sans-serif; }

.clientsbody {
  padding: 10px;
  text-align: left;
  color: #3d3d3d; }
  .clientsbody .title {
    font-size: 1.5em;
    font-family: "Source Sans Pro", sans-serif; }
  .clientsbody > .title {
    border-bottom: 4px solid #23A6A6;
    padding: 0;
    font-size: 30px;
    color: #000;
    padding-bottom: 6px;
    margin-bottom: 18px;
    margin-top: 32px; }
  .clientsbody .bold {
    font-weight: bold;
    font-size: 1.06em; }
  .clientsbody .container {
    background: #fff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); }

.adminheader {
  background-color: #1e616e;
  padding: 10px;
  text-align: left;
  color: #fff; }
  .adminheader .title {
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    font-family: "Source Sans Pro", sans-serif; }
  .adminheader .bread {
    color: #fff;
    font-weight: 400;
    font-size: 16px; }
  .adminheader .search {
    float: right; }
    .adminheader .search input {
      border: 2px solid #606060;
      font-family: "Source Sans Pro", sans-serif;
      border-radius: 20px;
      height: 24px;
      width: 233px;
      padding-left: 10px;
      padding-right: 10px; }
    .adminheader .search .av-button {
      background: #23A6A6; }
      .adminheader .search .av-button:hover {
        opacity: 0.8; }

.settings-section {
  text-align: left;
  box-sizing: border-box; }
  .settings-section .settingsbg {
    background: #d3d3d3; }
  .settings-section .changePass {
    background: #d3d3d3;
    width: 100%;
    margin: auto; }
  .settings-section .pass-field {
    display: block;
    width: 100%;
    max-width: 100%;
    font-size: 16px;
    font-family: "Source Sans Pro", sans-serif;
    padding: 10px 16px;
    box-sizing: border-box;
    border: 1px solid #ececec;
    background-color: #f8f8f8;
    color: #000; }
  .settings-section .confirm {
    text-align: center;
    margin-top: 12px; }
  .settings-section .label {
    margin-top: 12px;
    margin-bottom: 4px; }

.admingrid .cell {
  padding: 10px; }
.admingrid .title {
  font-size: 1.5em;
  font-family: "Source Sans Pro", sans-serif; }
.admingrid .gridcell {
  text-align: center;
  background: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  padding: 24px; }
  .admingrid .gridcell .title {
    font-size: 1.33em;
    margin-bottom: 30px;
    font-weight: bold; }
  .admingrid .gridcell .av-button {
    margin: 0; }

.loz {
  font-size: 18px;
  border: 2px solid #9a4b92;
  border-radius: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 18px;
  display: block;
  text-align: center;
  font-family: "Source Sans Pro", sans-serif; }
  .loz .loz-stat {
    display: block;
    font-weight: 600;
    font-size: 22px; }

.saved {
  padding-top: 10px;
  font-weight: bold;
  color: #36733c; }

.container.filter-wrap {
  background: #606060; }

.white-arrow, .l-grey-arrow, .black-arrow {
  float: right;
  width: 25px;
  height: 25px; }
  .white-arrow .innerarrow, .l-grey-arrow .innerarrow, .black-arrow .innerarrow {
    display: block;
    font-size: 0;
    width: 14px;
    height: 25px;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: all ease-out 0.1s; }
    .white-arrow .innerarrow.down, .l-grey-arrow .innerarrow.down, .black-arrow .innerarrow.down {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); }

.white-arrow .innerarrow {
  background-image: url(/static/media/white-chevron.160bc8ce.svg); }

.l-grey-arrow .innerarrow {
  background-image: url(/static/media/grey-chevron.9285376b.svg); }

.black-arrow .innerarrow {
  background-image: url(/static/media/black-chevron.bacdc9f4.svg); }

.filters {
  background: none;
  overflow: hidden;
  color: #fff; }
  .filters .av-button {
    padding-bottom: 11px;
    padding-top: 11px;
    background: #23A6A6;
    margin-left: 8px;
    margin-bottom: 8px; }
  .filters .title {
    text-align: left;
    font-size: 1.33em; }
  .filters .filterbox {
    border-top: 2px solid #fff;
    padding: 12px 12px 0 12px;
    margin-top: 8px;
    overflow: hidden; }
    .filters .filterbox .edit-field {
      margin-bottom: 4px; }
  .filters .filterbox:first-child {
    margin-top: 16px; }
  .filters .ddtitle {
    text-align: left;
    font-size: 22px;
    cursor: pointer;
    font-family: "Source Sans Pro", sans-serif; }
  .filters .filteritems {
    background: #FFFFFF; }
    .filters .filteritems > span {
      background: #d3d3d3;
      border-radius: 25px;
      height: 50px;
      margin-right: 8px;
      padding: 4px 8px;
      color: #606060;
      font-size: 14px;
      line-height: 32px;
      white-space: nowrap; }
    .filters .filteritems .filterx {
      display: inline-block;
      height: 16px;
      width: 16px;
      font-size: 0;
      background-image: url(/static/media/grey-close.23e02746.svg);
      background-repeat: no-repeat;
      vertical-align: -2.5px;
      margin-left: 4px;
      cursor: pointer; }

.bread {
  font-size: 1.19em;
  font-weight: bold;
  color: #777777;
  margin-top: 6px;
  margin-bottom: 12px; }
  .bread .crumb {
    cursor: pointer; }

.product {
  background: #fff;
  overflow: hidden;
  padding-top: 8px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); }
  .product .title {
    text-align: left;
    font-size: 1.33em;
    cursor: pointer;
    margin-right: 32px; }
  .product .arrow {
    font-size: 1.33em;
    float: right;
    cursor: pointer; }
  .product .black-arrow {
    width: 18px;
    height: 18px;
    margin-top: 4px; }
    .product .black-arrow .innerarrow {
      width: 10px;
      height: 18px; }

.expandbox {
  background: #d3d3d3;
  padding-top: 8px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 8px;
  overflow: hidden;
  font-family: "Source Sans Pro", sans-serif; }
  .expandbox .title {
    text-align: left;
    font-size: 1.33em;
    cursor: pointer;
    margin-right: 32px; }
  .expandbox .arrow {
    font-size: 1.33em;
    float: right;
    cursor: pointer; }
  .expandbox .black-arrow {
    width: 18px;
    height: 18px;
    margin-top: 4px; }
    .expandbox .black-arrow .innerarrow {
      width: 10px;
      height: 18px; }
  .expandbox .location {
    background: #FFFFFF;
    border: 1px solid #d3d3d3;
    font-size: 18px;
    font-family: "Source Sans Pro", sans-serif; }
  .expandbox .tap {
    border: 1px solid #3d3d3d;
    background: #FFFFFF;
    margin-left: 20px;
    margin-top: 4px;
    margin-right: 20px; }
  .expandbox .locationhead {
    background: #23A6A6;
    margin-top: 14px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 20px;
    font-weight: bold;
    border: 1px solid #23A6A6;
    color: #fff; }
  .expandbox.client-expandbox {
    background: none;
    border: none;
    border-bottom: 1px solid #d3d3d3;
    padding: 0; }
    .expandbox.client-expandbox .location {
      border-left: 2px solid #d3d3d3;
      border-right: 2px solid #d3d3d3; }
    .expandbox.client-expandbox .locationhead {
      border-left: 2px solid #23A6A6;
      border-right: 2px solid #23A6A6; }

.clientsvenues {
  padding: 10px;
  text-align: left;
  color: #3d3d3d; }
  .clientsvenues .title {
    font-size: 1.33em; }
  .clientsvenues .search {
    float: right; }
    .clientsvenues .search input {
      border: 2px solid #606060;
      font-family: "Source Sans Pro", sans-serif;
      border-radius: 20px;
      height: 24px;
      width: 233px;
      padding-left: 10px;
      padding-right: 10px; }

.edit-field {
  display: block;
  width: 100%;
  max-width: 100%;
  font-size: 16px;
  font-family: "Source Sans Pro", sans-serif;
  padding: 10px 16px;
  box-sizing: border-box;
  border: 2px solid #cecece;
  background-color: #f8f8f8;
  color: #000;
  border-radius: 4px;
  box-shadow: inset 0px 0px 9px rgba(0, 0, 0, 0.1); }

.generic_head {
  background: #FFFFFF;
  margin-right: 20px;
  margin-left: 20px;
  padding: 10px;
  text-align: left;
  color: #3d3d3d; }
  .generic_head .title {
    font-size: 1.33em; }
  .generic_head .search {
    float: right; }
    .generic_head .search input {
      border: 1px solid #3d3d3d;
      border-radius: 20px;
      height: 24px;
      width: 233px;
      padding-left: 10px;
      padding-right: 10px; }

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */ }

.modal-content {
  text-align: center;
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 300px; }

.filter-title {
  display: block;
  font-size: 16px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 25px;
  margin-bottom: 4px;
  padding-left: 32px; }
  .filter-title .checkmark {
    position: absolute;
    top: -1;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff; }
    .filter-title .checkmark:after {
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid #9a4b92;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }
  .filter-title input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .filter-title input:checked ~ .checkmark {
      background-color: #fff; }
      .filter-title input:checked ~ .checkmark:after {
        display: block; }
  .filter-title:hover input ~ .checkmark {
    background-color: #23A6A6; }
  .filter-title:hover input:checked ~ .checkmark {
    background-color: #23bcbc; }

.uploadImg {
  width: 95%; }

.modal-contact-box {
  text-align: center;
  color: #3d3d3d;
  background-color: #f2f2f2;
  margin: 7% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 600px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); }
  .modal-contact-box .title {
    text-align: left;
    font-size: 22px;
    padding-bottom: 12px;
    border-bottom: 2px solid #23A6A6;
    font-family: "Source Sans Pro", sans-serif; }
  .modal-contact-box .contact-section {
    text-align: left;
    background-color: #fff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    margin-top: 12px;
    margin-bottom: 12px; }
    .modal-contact-box .contact-section span {
      display: block;
      font-weight: bold;
      font-family: "Source Sans Pro", sans-serif;
      margin-bottom: 4px;
      font-size: 18px; }

.modal-box {
  text-align: center;
  color: #3d3d3d;
  background-color: #f2f2f2;
  margin: 7% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 600px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); }
  .modal-box .title {
    text-align: left;
    font-size: 22px;
    padding-bottom: 12px;
    border-bottom: 2px solid #23A6A6;
    font-family: "Source Sans Pro", sans-serif; }
  .modal-box .alert-title {
    font-size: 22px;
    padding-bottom: 32px;
    font-family: "Source Sans Pro", sans-serif; }
  .modal-box .modal-section {
    text-align: left;
    background-color: #fff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    margin-top: 12px;
    margin-bottom: 12px; }
    .modal-box .modal-section span {
      display: block;
      font-weight: bold;
      font-family: "Source Sans Pro", sans-serif;
      margin-bottom: 4px;
      font-size: 18px; }
    .modal-box .modal-section .field {
      border-bottom: 1px solid #d3d3d3;
      padding: 10px 16px;
      min-height: 20px; }
  .modal-box .edit-btn-box .av-button {
    margin-right: 0;
    float: right; }

.latestbox {
  background-color: #fff;
  overflow: hidden;
  padding: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px; }
  .latestbox .title {
    font-size: 1.25em; }
  .latestbox .loz {
    text-align: center;
    font-size: 0.8em; }
  .latestbox .small-5 .title {
    margin-top: 40px;
    max-height: 220px; }
    .latestbox .small-5 .title svg {
      max-height: 220px; }
  .latestbox .large-6.title span {
    height: 50px;
    display: inline-block; }

.scroller {
  margin-top: 12px;
  width: 100%;
  white-space: nowrap;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch; }
  .scroller .table {
    background-color: #fff;
    float: none;
    height: 90%;
    margin: 0 0.25%;
    display: inline-block;
    zoom: 1; }
  .scroller .date {
    padding: 6px;
    text-align: center;
    border-bottom: #eee solid 1px; }

thead {
  background: #23A6A6;
  color: #fff; }
  thead tr {
    color: #fff; }
    thead tr td.MuiTableCell-root {
      font-weight: bold; }
    thead tr th {
      color: #fff; }
      thead tr th.MuiTableCell-head {
        color: #fff; }

.over-latest-wrapper {
  padding: 0; }
  .over-latest-wrapper .over-latest {
    background: #fff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); }
    .over-latest-wrapper .over-latest .small-pie-wrap .pie-view {
      max-width: 280px;
      margin: 0 auto; }

.MuiPaper-rounded {
  border-radius: 0; }

.MuiTableCell-root {
  border-bottom: 1px solid #e0e0e0 !important; }

.small-latest-wrap {
  padding: 0 !important; }

.clientvenue-list {
  padding: 10px; }
  .clientvenue-list .client-item {
    padding: 0;
    margin-bottom: 4px; }

.product-list-wrap .product-item {
  padding: 0;
  margin-bottom: 4px; }

.details-wrap .deets_sect {
  display: block;
  background: #fff;
  margin-bottom: 12px;
  text-align: left;
  padding: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); }
  .details-wrap .deets_sect .title {
    font-weight: bold;
    font-size: 22px;
    padding-bottom: 12px;
    border-bottom: 2px solid #23A6A6; }
  .details-wrap .deets_sect .expandbox .title {
    border-bottom: 0; }
  .details-wrap .deets_sect .deet span {
    font-weight: bold;
    margin-top: 8px;
    display: block; }
  .details-wrap .deets_sect .deet .field {
    border-bottom: 2px solid #d3d3d3;
    background: #efefef;
    color: #606060;
    padding: 8px;
    min-height: 18px;
    margin-top: 4px; }
  .details-wrap .deets_sect .deets-list {
    background: #e5e5e5;
    padding: 0;
    padding-top: 8px;
    margin-bottom: 8px;
    margin-top: 8px;
    padding-bottom: 4px; }
    .details-wrap .deets_sect .deets-list > .cell > .title {
      font-size: 18px;
      padding-left: 8px; }
    .details-wrap .deets_sect .deets-list > .cell .title-note {
      margin-left: 8px;
      margin-top: 8px;
      display: block; }
    .details-wrap .deets_sect .deets-list .client-item.cell {
      padding: 4px 8px; }
      .details-wrap .deets_sect .deets-list .client-item.cell .expandbox {
        background: #bbb; }
        .details-wrap .deets_sect .deets-list .client-item.cell .expandbox .title {
          font-weight: 400;
          font-size: 20px; }
    .details-wrap .deets_sect .deets-list.dark-list {
      background: #606060; }
      .details-wrap .deets_sect .deets-list.dark-list > .cell > .title {
        color: #fff; }
      .details-wrap .deets_sect .deets-list.dark-list > .cell .title-note {
        color: #fff; }

.visit-wrap > .cell {
  margin-bottom: 12px; }
.visit-wrap .small-pie-wrap .pie-view {
  max-width: 320px;
  margin: 0 auto; }
.visit-wrap select {
  margin-bottom: 8px; }
.visit-wrap .subtitle {
  margin-top: 8px; }
.visit-wrap .visit-summary {
  background-color: #23A6A6;
  color: #fff; }
  .visit-wrap .visit-summary .title {
    font-weight: bold;
    border-bottom: 2px solid #fff;
    padding-bottom: 12px;
    margin-bottom: 12px; }
  .visit-wrap .visit-summary .subtitle {
    font-weight: bold;
    margin-top: 8px; }
.visit-wrap .summary-pro-clean .wastage .loz, .visit-wrap .summary-per-bar .wastage .loz {
  margin-top: 8px; }
  .visit-wrap .summary-pro-clean .wastage .loz .loz-stat, .visit-wrap .summary-per-bar .wastage .loz .loz-stat {
    font-size: 36px; }

.trend-wrap .trend-summary {
  background-color: #23A6A6;
  color: #fff; }
  .trend-wrap .trend-summary .title {
    font-weight: bold;
    border-bottom: 2px solid #fff;
    padding-bottom: 12px;
    margin-bottom: 12px; }
  .trend-wrap .trend-summary .subtitle {
    font-weight: bold;
    margin-top: 8px; }

.admin-wrap.cell {
  padding: 10px; }
.admin-wrap .admin-box {
  background: #fff;
  padding: 15px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); }
  .admin-wrap .admin-box .title {
    font-size: 1.5em;
    font-family: "Source Sans Pro", sans-serif;
    margin-bottom: 8px; }
.admin-wrap .admin-set-box {
  background: #1e616e;
  clear: both;
  color: #fff; }
  .admin-wrap .admin-set-box .av-button {
    background: #52D3F5; }
  .admin-wrap .admin-set-box .title-box.cell {
    padding-bottom: 0; }
  .admin-wrap .admin-set-box .title-box .title {
    color: #fff;
    font-size: 1.5em;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: bold;
    border-bottom: 2px solid #fff;
    padding-bottom: 12px; }
  .admin-wrap .admin-set-box > .cell:not(.title-box) {
    min-height: 74px; }
    .admin-wrap .admin-set-box > .cell:not(.title-box) .av-button {
      float: right; }
  .admin-wrap .admin-set-box input[type=checkbox] {
    margin-top: 14px; }
  .admin-wrap .admin-set-box .search input[type=text] {
    padding: 8px 12px;
    border-radius: 25px;
    border: 1px solid #606060;
    font-family: "Source Sans Pro", sans-serif;
    display: block;
    width: calc(100% - 30px);
    margin-top: 4px; }

.fix-list > .cell {
  padding: 4px; }

.feedback-deets {
  background: #d3d3d3; }
  .feedback-deets span {
    font-weight: bold;
    font-family: "Source Sans Pro", sans-serif; }
  .feedback-deets .field {
    font-family: "Source Sans Pro", sans-serif;
    border: 2px solid #909090;
    color: #000;
    padding: 8px;
    min-height: 18px;
    margin-top: 4px; }
  .feedback-deets .title {
    font-weight: bold;
    font-size: 22px;
    padding-bottom: 12px;
    border-bottom: 2px solid #23A6A6;
    font-family: "Source Sans Pro", sans-serif; }

.settingsbg .title {
  font-weight: bold;
  font-size: 22px;
  padding-bottom: 12px;
  border-bottom: 2px solid #23A6A6;
  font-family: "Source Sans Pro", sans-serif; }

.clienthead {
  margin-bottom: 8px; }

.filters-badge {
  background-color: white;
  color: red;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 20px;
  font-size: 18px;
  font-weight: bold;
  height: 20px;
  margin-left: 3px;
  border-radius: 50px; }

svg text:not(last-child) {
  color: #fff;
  fill: #fff; }

.tooltip {
  display: inline-block;
  position: relative;
  background-image: url(/static/media/tooltip.f65264ca.svg);
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  float: right;
  margin-top: 4px; }
  .tooltip .tooltip-reveal {
    display: none;
    background: #d3d3d3;
    top: 20px;
    left: -180px;
    padding: 8px;
    max-width: 250px;
    font-size: 14px;
    border: 1px solid #606060; }
  .tooltip:hover .tooltip-reveal {
    position: absolute;
    display: block; }

.bar-list {
  background: #a3a3a3;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  padding-top: 6px;
  padding-bottom: 6px; }
  .bar-list .no-pad .expandbox {
    margin: 6px 12px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); }
    .bar-list .no-pad .expandbox .bar-btns {
      float: right; }
      .bar-list .no-pad .expandbox .bar-btns .av-button {
        padding: 6px 22px;
        margin-right: 6px;
        font-size: 14px; }
        .bar-list .no-pad .expandbox .bar-btns .av-button:last-child {
          margin-right: 24px; }
    .bar-list .no-pad .expandbox .tap {
      margin: 0;
      border: 0;
      margin-bottom: 6px; }
    .bar-list .no-pad .expandbox .tap-head {
      background: #23A6A6;
      color: #fff;
      font-weight: bold;
      margin-bottom: 2px; }

.dot {
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 6px;
  margin-left: 6px;
  vertical-align: -1px; }

.dot_A, .dot_B, .dot_C, .dot_D {
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 6px;
  margin-left: 6px;
  vertical-align: -1px; }

.dot_A {
  background-color: #ae669a; }

.dot_B {
  background-color: #7a85aa; }

.dot_C {
  background-color: #96ceb2; }

.dot_D {
  background-color: #f7edbb; }

.uploads-section .uploads-title {
  margin-bottom: 6px;
  display: block; }
.uploads-section .badge {
  display: inline-block;
  height: 18px;
  min-width: 18px;
  border-radius: 9px;
  background-color: #ae669a;
  color: #fff;
  font-weight: bold;
  text-align: center;
  line-height: 16px;
  font-size: 14px; }
.uploads-section .photo {
  height: 120px;
  border: 1px solid #ddd;
  font-size: 0;
  margin-top: 6px; }
  .uploads-section .photo img {
    max-height: 118px;
    object-fit: contain;
    width: 100%;
    background-color: #fefefe; }

.cell.comment-box {
  padding: 8px 16px 8px 0; }
  .cell.comment-box .inner-comment {
    border: 1px solid #ddd; }

.change_up, .change_down, .change_none {
  display: inline-block; }

.change_up:before, .change_down:before {
  display: inline-block;
  content: "";
  height: 8px;
  width: 10px;
  margin-right: 4px; }

.change_none {
  display: inline-block;
  height: 2px;
  width: 18px;
  background: #999; }

.change_up:before {
  background: url(/static/media/increase.34a1f3b4.svg);
  background-repeat: no-repeat; }

.change_down:before {
  background: url(/static/media/decrease.701345ed.svg);
  background-repeat: no-repeat; }

.priority_low span {
  display: inline-block;
  padding: 4px 12px;
  color: #fff;
  font-weight: bold;
  border-radius: 50px;
  background: #4ab747; }

.priority_standard span {
  display: inline-block;
  padding: 4px 12px;
  color: #fff;
  font-weight: bold;
  border-radius: 50px;
  background: #fba61c; }

.priority_high span {
  display: inline-block;
  padding: 4px 12px;
  color: #fff;
  font-weight: bold;
  border-radius: 50px;
  background: #ee1c25; }

.nodatamsg {
  display: block;
  width: 100%;
  text-align: left;
  padding: 12px;
  font-size: 16px; }

.deets_sect .client-item.cell {
  padding: 5px; }

.nocom {
  font-style: italic;
  color: #999; }

.nomedia {
  display: block;
  font-style: italic;
  color: #999;
  margin-top: 6px; }

.delete {
  background-image: url(/static/media/delete.b508e9bd.svg);
  background-repeat: no-repeat;
  cursor: pointer; }

.printlogo {
  display: none; }

@media only screen and (max-width: 700px) {
  .footer {
    min-width: 0; }

  .navbar {
    height: auto;
    min-width: 0;
    padding-top: 0; }
    .navbar .navinfo {
      width: 100%;
      max-height: 40px;
      padding-bottom: 10px; }
      .navbar .navinfo .hamburger {
        background-image: url(/static/media/hamburger.54cbe818.svg);
        background-repeat: no-repeat;
        background-size: contain;
        height: 22px;
        width: 32px;
        display: inline-block;
        float: right;
        cursor: pointer;
        margin-top: 9px;
        margin-right: 12px; }
    .navbar.show {
      display: block; }
    .navbar a {
      text-decoration: none; }
    .navbar .navbutton, .navbar .notifbutton {
      display: block;
      width: 100%;
      box-sizing: border-box;
      border-radius: 0;
      border: 0;
      border-top: 1px solid #808080;
      background: #606060;
      text-decoration: none;
      font-size: 18px;
      text-align: left;
      padding: 14px 20px; }
      .navbar .navbutton:hover, .navbar .notifbutton:hover {
        background: #404040; } }
@media only print and (min-width: 300px) {
  .print-1 {
    width: calc( (100% / 12) * 1 ); }

  .print-2 {
    width: calc( (100% / 12) * 2 ); }

  .print-3 {
    width: calc( (100% / 12) * 3 ); }

  .print-4 {
    width: calc( (100% / 12) * 4 ); }

  .print-5 {
    width: calc( (100% / 12) * 5 ); }

  .print-6 {
    width: calc( (100% / 12) * 6 ); }

  .print-7 {
    width: calc( (100% / 12) * 7 ); }

  .print-8 {
    width: calc( (100% / 12) * 8 ); }

  .print-9 {
    width: calc( (100% / 12) * 9 ); }

  .print-10 {
    width: calc( (100% / 12) * 10 ); }

  .print-11 {
    width: calc( (100% / 12) * 11 ); }

  .print-12 {
    width: calc( (100% / 12) * 12 ); }

  html {
    background: #fff; }

  .printlogo {
    display: block;
    width: 200px;
    height: 43px; }

  .navbar {
    display: none; }

  .container.filter-wrap {
    display: none; }

  .clientsheader {
    background-color: #fff; }
    .clientsheader .bread {
      color: #3d3d3d; }
    .clientsheader .title {
      color: #3d3d3d; }
    .clientsheader .tabs {
      display: none; }

  .clientsbody .container {
    border: 1px solid #888;
    box-shadow: none; }

  .user_deets .deets_sect {
    border: 1px solid #888;
    box-shadow: none; }

  .visit-wrap .visit-summary {
    color: #3d3d3d;
    background-color: #fff; }
    .visit-wrap .visit-summary .title {
      border-bottom: 2px solid #000; }
  .visit-wrap .subtitle {
    display: none; }
  .visit-wrap select.edit-field {
    border: 0;
    background-color: #fff;
    box-shadow: none;
    padding: 0;
    background-image: none; }

  .MuiPaper-root.MuiTablePagination-root.MuiPaper-elevation1.MuiPaper-rounded {
    display: none; }

  .MuiTableHead-root {
    background-color: #fff;
    color: #3d3d3d; } }

/*# sourceMappingURL=main.css.map */

